.slick-slide {
	opacity: 0.75;
	transition: all 150ms ease-out;
	transform: scale(0.95);
}

.slick-slide.slick-center {
	opacity: 1;
	transform: scale(1);
}
